import React, { Component } from 'react';
import 'react-table/react-table.css';

import { connect } from 'react-redux';
import Header from '../header';
import Footer from '../footer';
import { Card, Col, Row } from 'react-bootstrap';

class Index extends Component {

  render() {
    return (
      <>
        <Header />

        <Card style={{ padding: "30px" }}>
          <Row>

            <Col md="12">

              <h5><b>Quality and Design</b></h5>

              <p>
                At Nawashri Garment Factory, customer satisfaction and product quality are at the heart of everything we do. We firmly believe that the key to making our customers and partners happy lies in delivering products that exceed expectations in both quality and design.
              </p>

              <p>
                <div><b>Commitment to Quality</b></div>
                We take pride in our unwavering commitment to producing high-quality garments. With the expertise of our experienced and dedicated team, we ensure that every product meets the highest standards of craftsmanship.

                <ul style={{ paddingLeft: "15px" }}>
                  <li><b>Experienced Production Team:</b> Our Production Managers and Supervisors bring years of expertise to the table, ensuring that every garment reflects our dedication to quality.</li>
                  <li><b>Quality Control:</b> From fabric sourcing to the final stitch, our Quality Control Department meticulously oversees every stage of production. This rigorous process guarantees that the end result meets international standards.</li>
                  <li><b>Global Reputation:</b> Our dedication to quality has earned us the trust of reputed brands across Europe, solidifying our position as a reliable partner in the garment industry.</li>
                </ul>
              </p>

              <p>
                <div><b>Creative Design Capabilities</b></div>
                At Nawashri, we understand the importance of staying ahead in the ever-evolving fashion industry. That’s why we offer both custom and in-house design options:

                <ul style={{ paddingLeft: "15px" }}>
                  <li><b>Partner-Driven Designs:</b> We can manufacture garments based on patterns, samples, or specifications provided by our partners, ensuring their unique vision comes to life.</li>
                  <li><b>In-House Design Team:</b> Our creative designers stay up to date with the latest trends, ensuring that our collections are modern, stylish, and relevant. Whether you need a bespoke design or inspiration for your next line, we’ve got you covered.</li>
                </ul>
              </p>

              <p>
                <div><b>Why Choose Us?</b></div>
                At Nawashri, we understand the importance of staying ahead in the ever-evolving fashion industry. That’s why we offer both custom and in-house design options:

                <ul style={{ paddingLeft: "15px" }}>
                  <li><b>Customer-Centric Approach:</b> Your satisfaction is our top priority.</li>
                  <li><b>End-to-End Quality Assurance:</b> High standards maintained at every step of production.</li>
                  <li><b>Design Versatility:</b> Flexibility to create custom designs or provide trend-focused in-house collections.</li>
                  <li><b>Experienced Team:</b> A dedicated workforce committed to excellence in quality and design.</li>
                </ul>

                At Nawashri, we aim to blend creativity with craftsmanship, ensuring that every piece of clothing we produce is a reflection of our passion for quality and innovation. Choose us as your partner, and experience the perfect balance of comfort, style, and quality.

              </p>


              <p>
                Thank you for choosing Nawashri — where quality meets style!
              </p>

            </Col>


          </Row>
        </Card>

        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
});
export default connect(
  mapStateToProps,
  {}
)(Index);
