import React, { Component } from 'react';
import 'react-table/react-table.css';

import { connect } from 'react-redux';
import Header from '../header';
import MainContent from './MainContent';
import Footer from '../footer';

class Index extends Component {
  componentDidMount() {
  }

  render() {
    return (
      <>
        <Header />
        <MainContent />
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
});
export default connect(
  mapStateToProps,
  {}
)(Index);
