import React, { Component } from 'react';
import 'react-table/react-table.css';

import { connect } from 'react-redux';
import Header from '../header';
import Footer from '../footer';
import { Col, Row } from 'react-bootstrap';

import Image1 from '../../assets/images/about/fac1.png';
import Image2 from '../../assets/images/about/fac2.png';
import Image4 from '../../assets/images/about/fac4.png';

class Index extends Component {

  render() {
    return (
      <>
        <Header />


        <Row>
          <Col md="5" style={{paddingLeft: "15px"}}>

            <div>
              <img
                className="carousel-image d-block w-100"
                src={Image1}
                alt="Third slide"
              />
            </div>

            <div>
              <img
                className="carousel-image d-block w-100"
                src={Image2}
                alt="Third slide"
              />
            </div>



          </Col>
          <Col md="7" style={{ paddingLeft: "30px", paddingTop: "15px" }}>

            <h5><b>About Us</b></h5>

            <p>
              Nawashri Garment Factory is a proud, locally established business based in Kathmandu, Nepal. We are a leading provider of high-quality garments, specializing in men's, women's, and children's clothing. Our commitment to delivering excellent products, paired with our dedication to serving customers across Nepal, has earned us a trusted reputation in the garment industry.
            </p>

            <p>
              <div><b>Our Vision:</b></div>
              At Nawashri, our vision is to empower the Nepalese market with affordable, stylish, and high-quality clothing that caters to all seasons and lifestyles. We strive to bridge global textile innovations with the preferences of the local community, ensuring that our products meet both current trends and practical needs.
            </p>

            <p>
              <div><b>Wide Range of Clothing:</b></div>
              We pride ourselves on offering a diverse range of garments suitable for everyone, regardless of age or gender. Our product categories include:
              <ul style={{ paddingLeft: "15px" }}>
                <li><b>Men's Clothing:</b> From everyday wear to trendy designs, we provide durable and comfortable clothing for men that reflects modern style.</li>
                <li><b>Women's Clothing:</b> Our women's collection features versatile options, ranging from stylish jackets to chic seasonal outfits, tailored to highlight individuality and elegance.</li>
                <li><b>Kids' Clothing:</b> For the younger generation, we offer fun, durable, and comfortable options, designed to keep up with their active lifestyles while ensuring safety and warmth.</li>
              </ul>

              Our designs are carefully crafted to meet the varying needs of Nepal’s diverse culture and climate, ensuring that everyone can find something they love in our collection.
            </p>

            <p>
              <div><b>Nationwide Reach:</b></div>
              One of our key strengths lies in our ability to deliver products to all seven provinces of Nepal. Whether you are in the bustling city of Kathmandu, the serene hills of Pokhara, or the remote regions of the Terai, Nawashri ensures timely and reliable delivery. Our robust logistics network makes it possible to serve customers across the country, reinforcing our commitment to accessibility and convenience.
            </p>


            <p>
              <div><b>OEM and Bulk Customization:</b></div>
              At Nawashri, we specialize in OEM (Original Equipment Manufacturer) services, making us the go-to choice for businesses looking to create their own clothing lines. Whether you’re a retail store, an organization, or an event planner, we offer bulk customization services to meet your specific needs.

              <ul style={{ paddingLeft: "15px" }}>
                <li>Custom designs based on your vision.</li>
                <li>Flexible order quantities to accommodate large-scale demands.</li>
                <li>Tailored branding and labeling to help establish your unique identity.</li>
              </ul>

              Our experienced team works closely with you to ensure that your customized garments align perfectly with your requirements, maintaining the highest quality standards every step of the way.
            </p>

            <p>
              <div><b>Dedicated Designers and Quality Assurance:</b></div>
              At the heart of Nawashri’s operations is our talented team of designers and quality testers.

              <ul style={{ paddingLeft: "15px" }}>
                <li><b>Design Team:</b> Our creative designers bring a wealth of experience and a keen understanding of global trends and local preferences. They work tirelessly to create designs that are both innovative and functional, ensuring that our products meet the dynamic needs of our customers.</li>
                <li><b>Quality Assurance Team:</b> Every product at Nawashri undergoes rigorous quality checks. From fabric selection to stitching and finishing, our quality testers ensure that each garment meets our strict standards before it reaches the customer.</li>
              </ul>

              This dual focus on design and quality allows us to consistently deliver clothing that is stylish, comfortable, and long-lasting.
            </p>


            <p>
              <div><b>Global-Quality Fabrics:</b></div>
              To ensure premium quality, we source our fabrics from some of the most reputable textile-producing countries in the world, including:

              <ul style={{ paddingLeft: "15px" }}>
                <li><b>Bangladesh:</b> Known for its high-quality and cost-effective textile production.</li>
                <li><b>China:</b> A leader in innovative fabrics and advanced textile technology.</li>
                <li><b>Vietnam:</b> Renowned for its durable and sustainable materials.</li>
              </ul>

              By collaborating with global suppliers, we can offer a range of fabrics that not only enhance the look and feel of our garments but also ensure their durability and comfort.
            </p>

            <p>
              <div><b>Why Choose Nawashri?</b></div>
              To ensure premium quality, we source our fabrics from some of the most reputable textile-producing countries in the world, including:

              <ul style={{ paddingLeft: "15px" }}>
                <li><b>Wide Range of Offerings:</b> We cater to men, women, and children, providing a one-stop solution for all your clothing needs.</li>
                <li><b>Nationwide Service:</b> With a reliable delivery network, we ensure that our products reach customers across all provinces of Nepal.</li>
                <li><b>Customization Expertise:</b> Our OEM and bulk customization services make us the ideal partner for businesses and organizations.</li>
                <li><b>Quality You Can Trust:</b> With a dedicated team of designers and quality testers, we guarantee products that meet the highest standards.</li>
                <li><b>Global Fabrics:</b> By importing fabrics from Bangladesh, China, and Vietnam, we bring international quality to the local market.</li>
              </ul>
            </p>

            <p>
              <div><b>Sustainability and Ethical Practices</b></div>
              At Nawashri, we are committed to ethical and sustainable practices.
              <ul style={{ paddingLeft: "15px" }}>
                <li>We prioritize using eco-friendly materials wherever possible.</li>
                <li>Our production processes are designed to minimize waste and environmental impact.</li>
                <li>We ensure fair wages and safe working conditions for all our employees, reflecting our commitment to social responsibility.</li>
              </ul>
            </p>

            <p>
              <div><b>Customer-Centric Approach</b></div>
              Customer satisfaction is at the core of everything we do. Whether it’s designing a new clothing line, fulfilling a bulk order, or ensuring prompt delivery, our team is dedicated to exceeding customer expectations. We value feedback and continuously strive to improve our products and services to better serve our community.
            </p>

          </Col>

          <Col md="12" style={{paddingLeft: "15px"}}>
            <div>
              <img
                className="carousel-image sm d-block w-100"
                src={Image4}
                alt="Third slide"
                style={{ height: "200px", marginTop: "3px", marginBottom: "2px" }}
              />
            </div>

          </Col>
        </Row>



        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
});
export default connect(
  mapStateToProps,
  {}
)(Index);
