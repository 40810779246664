import React, { Component } from 'react';
import 'react-table/react-table.css';

import { connect } from 'react-redux';
import Header from '../header';
import Footer from '../footer';
import { Card, Col, Row } from 'react-bootstrap';

class Index extends Component {

  render() {
    return (
      <>
        <Header />

        <Card style={{ padding: "30px" }}>
          <Row>

            <Col md="12">

              <h5><b>Contact Us</b></h5>
              <p>
                At <b>Nawashri Garment Factory</b>, we’re committed to providing exceptional service and high-quality garments to meet your needs. Whether you're an individual looking for stylish clothing or a business seeking bulk orders, we’re here to assist you every step of the way.

                <br /><br />
                <div><b>How to Reach Us</b></div>
                We offer multiple channels for you to get in touch with us:


                <ul style={{ paddingLeft: "15px" }}>
                  <li><b>Email:</b> nawashrigarment@gmail.com.
                    <div>Send us an email with your inquiries, orders, or feedback, and our team will respond promptly.</div>
                  </li>

                  <li><b>Phone:</b> +977 9869068777, +977 9843632248
                    <div>Feel free to call us for any questions or detailed discussions about your requirements.</div>
                  </li>

                  <li><b>Whatsapp:</b> +977 9843632248
                    <div>Connect with us on WhatsApp for quick communication, image sharing, or order updates.</div>
                  </li>
                </ul>
              </p>

              <p>
                <div><b>What We Offer</b></div>

                <ul style={{ paddingLeft: "15px" }}>
                  <li><b>High-Quality Garments:</b> Explore our range of clothing for men, women, and children, crafted to perfection for all seasons.</li>
                  <li><b>Bulk Orders:</b> We specialize in OEM and customized bulk production tailored to your specifications.</li>
                  <li><b>Personalized Service:</b> Our dedicated team is ready to guide you through the process, ensuring you get exactly what you’re looking for.</li>
                </ul>
              </p>

              <p>
                <div><b>Why Choose Nawashri Garment Factory?</b></div>

                <ul style={{ paddingLeft: "15px" }}>
                  <li><b>Expertise:</b> Years of experience in the garment industry.</li>
                  <li><b>Innovation:</b> Modern designs blended with traditional craftsmanship.</li>
                  <li><b>Customer Focus:</b> Your satisfaction is our priority.</li>
                  <li><b>Nationwide Service:</b> We deliver to all provinces of Nepal.</li>
                </ul>

              </p>

              <p>
                <div><b>Join Us on Our Journey</b></div>

                As we continue to grow and innovate, we invite you to be a part of our journey in transforming the Nepalese fashion landscape. At Nawashri Garment Factory, we combine tradition and modernity to create clothing that tells a story of craftsmanship, quality, and care.

                <br />
                Let us redefine your style and comfort—one outfit at a time.
              </p>

              <p>
                <div><b>Visit Us Today</b></div>

                Feel free to reach out through any of the channels above or visit us in person at our factory in Kathmandu, Nepal. We look forward to hearing from you!

              </p>


              <p>
                Thank you for choosing Nawashri Garment Factory—your trusted partner in fashion.
              </p>

            </Col>


          </Row>
        </Card>

        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
});
export default connect(
  mapStateToProps,
  {}
)(Index);
