// https://appdividend.com/2018/11/11/react-crud-example-mern-stack-tutorial/

import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  withRouter,
  NavLink,
} from "react-router-dom";
import {
  Modal,
  Button,
  Row,
  Col,
  Form,
  Nav,
  NavDropdown,
  Navbar,
} from "react-bootstrap";
import logo from "./logo.svg";
import "./App.css";
import "./assets/css/frontend/styles.css";
import "./assets/css/frontend/margin-padding.css";

import frontendStore from "./redux/frontend/store";
import { Provider } from "react-redux";

import NavStoreFrontend from "./config/navStoreFrontend";
import { ToastContainer } from "react-toastify";

const App = () => {
  return (
    <>
      <Provider store={frontendStore}>
        <ToastContainer
          autoClose={5000}
          position="bottom-right"
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          pauseOnHover
          draggable
        />

        {/* <Router basename={'/'}> */}
        {/* <Router basename={'/eztoz/prod/'}> */}
        <Router basename={process.env.PUBLIC_URL}>
          <Route path="/" component={NavStoreFrontend} />
        </Router>
      </Provider>
    </>
  );
};

export default App;
