import React, { Component } from 'react';
import 'react-table/react-table.css';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import { connect } from 'react-redux';
import Logo from '../../assets/images/logo-b-2.png';

class Index extends Component {

  render() {
    return (
      <>
        <Navbar bg="dark" data-bs-theme="dark" expand="lg" className="b--g-body-tertiary">
          <Container className='no-margin'>
            <Navbar.Brand href="/">
              <img
                className="logo-image"
                src={Logo}
                alt="First slide"
              />
            </Navbar.Brand>

            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link href="/">Home</Nav.Link>
                <Nav.Link href="/about-us">About Us</Nav.Link>
                {/* <Nav.Link href="#link">Gallery</Nav.Link> */}
                <Nav.Link href="/quality-and-design">Quality & Design</Nav.Link>
                <Nav.Link href="/contact-us">Contact Us</Nav.Link>
              </Nav>

              <Nav className='nav-right'>
                <Nav.Link eventKey={2} href="#memes">

                  <div>
                    <span className='label'><i class='fa fa-envelope' aria-hidden='true'></i></span>
                    <span className='detail'>nawashrigarment@gmail.com</span>
                  </div>

                  <div>
                    <span className='label'><i class='fa fa-phone' aria-hidden='true'></i></span>
                    <span className='detail'>+977 9869068777 
                      <div style={{marginLeft: "18px"}}>
                      +977 9843632248 (whatsapp)
                      </div>
                      </span>
                  </div>


                  <div>
                  <span className='label'><i class='fa fa-map-marker' aria-hidden='true'></i></span>
                    <span className='detail'>Kathmandu, Nayabazaar</span>
                  </div>

                </Nav.Link>
              </Nav>

            </Navbar.Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}

const mapStateToProps = (state) => ({

});
export default connect()(Index);
