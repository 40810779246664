import React from "react";

import {
  BrowserRouter as Router,
  Route,
} from "react-router-dom";


// FRONTEND
import Homepage from "../feature-frontend/homepage";
import AboutUs from "../feature-frontend/page/AboutUs";
import ContactUs from "../feature-frontend/page/ContactUs";
import QualityNDesign from "../feature-frontend/page/QualityNDesign";

const NavPharmacy = () => {
  return (
    <>
      <Route exact path="/" component={Homepage} />
      <Route exact path="/home" component={Homepage} />
      <Route exact path="/about-us" component={AboutUs} />
      <Route exact path="/contact-us" component={ContactUs} />
      <Route exact path="/quality-and-design" component={QualityNDesign} />
    </>
  );
};

export default NavPharmacy;
