import React, { Component } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import 'react-table/react-table.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CardGroup from 'react-bootstrap/CardGroup';

class Index extends Component {
  render() {
    return (
      <>
        <CardGroup className='footer'>
          <Card>
            <Card.Body>
              <Card.Title>What we do</Card.Title>
              <Card.Text>
              Nawashri is a garment factory dedicated to crafting quality fabric clothing for Nepal's local market. <br />
              Blending current trends with versatile designs, we create stylish and <br />
              comfortable clothing lines for men, women, and children, catering to all seasons
              </Card.Text>
            </Card.Body>
            {/* <Card.Footer>
              <small className="text-muted">Last updated 3 mins ago</small>
            </Card.Footer> */}
          </Card>
          <Card>
            <Card.Body>
              <Card.Title>Collections</Card.Title>
              <Card.Text>
                <ul style={{ paddingLeft: "15px"}}>
                  <li>Puffer Jackets – Perfect for staying warm and stylish.</li>
                  <li>Hoodies – Cozy and trendy for casual wear.</li>
                  <li>Woolen Shirts – Comfortable and ideal for chilly days.</li>
                  <li>Women's Clothing – Elegant designs for all seasons.</li>
                  <li>Kids' Clothing – Fun, durable, and season-ready apparel.</li>
                  <li>& more...</li>
                  </ul>
              </Card.Text>
            </Card.Body>
           
          </Card>
          <Card>
            <Card.Body>
              <Card.Title>Contact Us</Card.Title>
              <Card.Text>
              Email: nawashrigarment@gmail.com <br />
              Phone: +977 9869068777, +977 9843632248 <br />
              Whatsapp: +977 9843632248 <br />
              Stay Connected: Feel free to reach out for inquiries or orders. We're here to assist you!
              </Card.Text>
            </Card.Body>
          </Card>



        </CardGroup>

        <Row className='footer-last'>
          <Col
            className='12'
          >
            @copyright 2024 - powered by desh&group 
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
});
export default connect(
  mapStateToProps,
  {}
)(withRouter(Index));
