import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Image1 from '../../assets/images/cover-1.jpeg';
import Image2 from '../../assets/images/cover-2.jpg';
import Image3 from '../../assets/images/cover-3.jpg';

import Image4 from '../../assets/images/banner-1.png';
import Image5 from '../../assets/images/banner-2.png';
import Image6 from '../../assets/images/banner-3.png';
import Image7 from '../../assets/images/banner-4.png';
import ImageGallery1 from '../../assets/images/gallery-1.png';
import ImageTextImageRight from '../../assets/images/text-imageRight.png';

import { Row, Col } from 'react-bootstrap';

function DarkVariantExample() {
  return (
    <>
      <Carousel interval={2500} data-bs-theme="dark">
        <Carousel.Item>
          <img
            className="carousel-image d-block w-100"
            src={Image3}
            alt="Third slide"
          />

          <Carousel.Caption>
            <h5>Everyday Comfort, Timeless Style – The Regular Puffer Jacket!</h5>
            <p>Effortlessly versatile, keeping you warm all day, every day.</p>
          </Carousel.Caption>
        </Carousel.Item>


        <Carousel.Item>
          <img
            className="carousel-image d-block w-100"
            src={Image2}
            alt="Second slide"
          />
          <Carousel.Caption>
            <h5>Conquer the Cold with Our Winter Puffer Jacket!</h5>
            <p>Ultimate warmth, unbeatable comfort, made for the season.</p>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img
            className="carousel-image d-block w-100"
            src={Image1}
            alt="First slide"
          />
          <Carousel.Caption>
            <h5>Stay Warm in Style with Our Snowy Puffer Jacket!</h5>
            <p>Lightweight, cozy, and perfect for every winter adventure.</p>
          </Carousel.Caption>
        </Carousel.Item>




        {/* <Carousel.Item>
        <img
          className="d-block w-100"
          src="../../assets/images/image-8-1024x585.jpeg"
          alt="Third slide"
        />
        <Carousel.Caption>
          <h5>Third slide label</h5>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p>
        </Carousel.Caption>
      </Carousel.Item> */}
      </Carousel>

      <Row>
        <Col md='12' style={{ textAlign: 'center' }}>
          <img
            className="carousel-image d-block w-100"
            src={Image4}
            alt="Hot products"
            style={{ height: "900px" }}
          />
        </Col>


        <Col md='12' style={{ textAlign: 'center' }}>
          <img
            className="carousel-image d-block w-100"
            src={Image7}
            alt="Hot products"
            style={{ height: "600px" }}
          />
        </Col>

        {/* 
<Col md='12' style={{textAlign: 'center' }}>
  <img
    className="carousel-image d-block w-100"
    src={Image5}
    alt="Hot products"
    style={{ height: "200px" }}
  />
</Col> */}


        <Col md='12' style={{ textAlign: 'center' }}>
          <img
            className="carousel-image d-block w-100"
            src={ImageGallery1}
            alt="Hot products"
            style={{ height: "900px" }}
          />
        </Col>


        <Col md='12' style={{ textAlign: 'center' }}>
          <img
            className="carousel-image d-block w-100"
            src={ImageTextImageRight}
            alt="Hot products"
            style={{ height: "500px" }}
          />
        </Col>


        {/* 
        <Col md='12' style={{ textAlign: 'center' }}>
          <img
            className="carousel-image d-block w-100"
            src={Image6}
            alt="Hot products"
            style={{ height: "900px" }}
          />
        </Col> */}


      </Row>

    </>
  );
}

export default DarkVariantExample;